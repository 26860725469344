import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (

        <footer className='footer'>
            <div className="grp-link">
                <Link to={`/Credits`} >Crédits</Link>
            </div>
        </footer>


    );


};

export default Footer;