import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import photo_homme from './../assets/about/nicolas-cool.webp';
import photo_duvet from './../assets/about/lucas-canino.webp';

let cannonicalLink = "https://www.monchamois.com/About";
let metaDescription = "Venez mieux nous connaître, nos valeurs, nos passions";


const About = () => {

    return (
        <div className='about'>
        <Helmet>
                <title>Nous connaître</title>
                <meta name="description" content={metaDescription}></meta>
            <link rel="canonical" href={cannonicalLink}></link>
         </Helmet>
            <header className='header'>
                <div className='background'>
                    <Navbar />
                    <div className="textbox">
                        <h1>Nous connaître</h1> 
                    </div>
                </div>
            </header>
         <section className="section-about">
            <div className="bloc-about">
                <div className="sous-bloc">
                    <div className="sous-bloc-text">
                        <h2>A chacun sa montagne</h2>
                        <p>La France est une destination de choix pour les amateurs de ski, offrant une diversité impressionnante de stations adaptées à tous les niveaux et à tous les budgets.</p>
                        <p>Que ce soit pour un week-end, une semaine ou un séjour prolongé, les skieurs du monde entier choisissent la France pour ses installations modernes et ses paysages à couper le souffle.</p>
                    </div>
                    <div className="sous-block-image">
                        <img src={photo_duvet} alt="un-couple-de-personnes-assis-sur-une-couverture-rouge" />
                    </div>
                </div>
                <div className="bloc-line">
                    <div className="line">
                        
                    </div>
                </div>

                <div className="sous-bloc">
                    <div className="sous-block-image">
                        <img src={photo_homme} alt="personne-debout-devant-la-photographie-de-paysage-de-montagne" />
                    </div>
                    <div className="sous-bloc-text">
                        <h2>Vos vacances, vos critères</h2>
                        <p>Notre volonté est de simplifier et de rendre accessible au plus grand nombre les vacances à la montagne l’hiver.</p>
                        <p>Nous souhaitions vous proposer un maximum de critères pour trouver la station de vos rêves.</p>
                    </div>
                    
                </div>

            </div>
         </section>
         <Footer />
        </div>
    );
};

export default About;