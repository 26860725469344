import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

let metaDescription = "Page non trouvée";

const NotFound = () => {
   return (
       <div className='notfound'>
        <Helmet>
                <title>Page non trouvée</title>
                <meta name="description" content={metaDescription}></meta>
           
         </Helmet>
   <header className='header'>
    <div className='background'>
     <Navbar />
     <div className='textbox'>
       <h1>Attention sortie de piste !!!!!</h1>
       <p> Voilà le risque lorsque l'on s'aventure hors des sentiers
        <br />
         <br /> On se prend une ERREUR 404
     </p>
       <Link to= "/" className='hero-btn'> Retour à l'accueil</Link>
     </div>
   </div>
  </header>
               
  <Footer />  

       </div>
      
   );
};

export default NotFound;