import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

let cannonicalLink = "https://www.monchamois.com/Credits";
let metaDescription = "Crédtis";


const Credits = () => {

    return (
        <div className='credits'>
             <Helmet>
                <title>Crédits</title>
                <meta name="description" content={metaDescription}></meta>
                 <link rel="canonical" href={cannonicalLink}></link>
            </Helmet>  
            <header className='header'>
                <div className='background'>
                    <Navbar />
                    <div className="textbox">
                        <h1>Crédits</h1> 
                    </div>
                </div>
            </header>
         <div className='body-block'>
         <section className="section-credit-photo">
            <div className="sec-credit-photo-header">
                    <h2>Crédits Photos</h2>
            </div>
            <div className="bloc-credit-photo">
                <div className="bloc-credit-photo-header">
                    <h3>Page Nous connaître</h3>
                </div>
                <div className="sous-bloc">
                    <ul>
                        <li>Photo de <a href="https://unsplash.com/fr/@shotz?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Nicolas Cool</a> sur <a href="https://unsplash.com/fr/photos/personne-debout-devant-la-photographie-de-paysage-de-montagne-SpNbLsJuuYQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                        <li>Photo de <a href="https://unsplash.com/fr/@lcanino?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Lucas Canino</a> sur <a href="https://unsplash.com/fr/photos/un-couple-de-personnes-assis-sur-une-couverture-rouge-0LARSp0JVmQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                    </ul>      
                </div>
            </div>

            <div className="bloc-credit-photo">
                <div className="bloc-credit-photo-header">
                    <h3>Page Accueil</h3>
                </div>
                <div className="sous-bloc">
                    <ul>
                        <li>Photo de <a href="https://unsplash.com/fr/@_vdhd?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Tim Vanderhoydonck</a> sur <a href="https://unsplash.com/fr/photos/une-remontee-mecanique-qui-monte-une-montagne-enneigee-QV58wLRuV6I?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                    </ul>      
                </div>
            </div>
                


         </section>
         </div>
         <Footer />
        </div>
    );
};

export default Credits;

